import {
  Button,
  ButtonVariants,
  IEntitlement,
  IProfile,
  VendorNames,
  getGivenNameFromProfile,
  getPrimaryAddressFromProfile,
  getPrimaryPhoneNumberFromProfile,
} from '@comptia-sso/core';
import { ReactElement } from 'react';

// Styles.
import styles from '../ProfileMergeView.module.scss';

interface ISubmittedMergeRecordProps {
  entitlements: IEntitlement[];
  isIneligibleAsWinner?: boolean;
  isSelected: boolean;
  onSelect(id: string): void;
  profile?: IProfile;
  recordNumber: number;
}

export const SubmittedMergeRecord = (
  props: ISubmittedMergeRecordProps,
): ReactElement => {
  const {
    entitlements,
    isIneligibleAsWinner,
    isSelected,
    onSelect,
    profile,
    recordNumber,
  } = props;
  const address = getPrimaryAddressFromProfile(profile);
  const phoneNumber = getPrimaryPhoneNumberFromProfile(profile);

  return (
    <div className={styles.record}>
      <header>
        <h3>
          Record {recordNumber}
          <span>{getGivenNameFromProfile(profile)}</span>
        </h3>
      </header>
      <div>
        <div className={styles.recordSection}>
          <div className={styles.recordTitle}>CompTIA ID</div>
          <div className={styles.recordDetail}>{profile?.compTIAId || '-'}</div>

          <div className={styles.recordTitle}>Profile ID</div>
          <div className={styles.recordDetail}>{profile?.id || '-'}</div>

          <div className={styles.recordTitle}>SSO ID</div>
          <div className={styles.recordDetail}>{profile?.ssoId || '-'}</div>

          <div className={styles.recordTitle}>{VendorNames.BenchPrep} ID</div>
          <div className={styles.recordDetail}>
            {profile?.externalIds?.benchPrep || '-'}
          </div>

          <div className={styles.recordTitle}>
            {VendorNames.CertMasterPractice} ID
          </div>
          <div className={styles.recordDetail}>
            {profile?.externalIds?.certMasterPractice || '-'}
          </div>

          <div className={styles.recordTitle}>{VendorNames.Gutenberg} ID</div>
          <div className={styles.recordDetail}>
            {profile?.externalIds?.gutenberg || '-'}
          </div>

          <div className={styles.recordTitle}>{VendorNames.Optimizely} ID</div>
          <div className={styles.recordDetail}>
            {profile?.externalIds?.optimizely || '-'}
          </div>

          <div className={styles.recordTitle}>{VendorNames.PearsonVue} ID</div>
          <div className={styles.recordDetail}>
            {profile?.externalIds?.pearsonVue || '-'}
          </div>

          <div className={styles.recordTitle}>{VendorNames.Skillable} ID</div>
          <div className={styles.recordDetail}>
            {profile?.externalIds?.skillable || '-'}
          </div>

          <div className={styles.recordTitle}>Product(s)</div>
          <div className={styles.recordDetail}>
            {entitlements.length > 0 ? (
              <ul>
                {entitlements.map((entitlement) => {
                  return (
                    <li key={entitlement.id}>{entitlement.product?.title}</li>
                  );
                })}
              </ul>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className={styles.recordSection}>
          <div className={styles.recordTitle}>First Name</div>
          <div className={styles.recordDetail}>
            {profile?.givenName?.firstName || '-'}
          </div>

          <div className={styles.recordTitle}>Last Name</div>
          <div className={styles.recordDetail}>
            {profile?.givenName?.lastName || '-'}
          </div>

          <div className={styles.recordTitle}>
            Preferred
            <br /> First Name
          </div>
          <div className={styles.recordDetail}>
            {profile?.preferredName?.firstName || '-'}
          </div>

          <div className={styles.recordTitle}>
            Preferred
            <br /> Last Name
          </div>
          <div className={styles.recordDetail}>
            {profile?.preferredName?.lastName || '-'}
          </div>

          <div className={styles.recordTitle}>Email</div>
          <div className={styles.recordDetail}>{profile?.email}</div>

          <div className={styles.recordTitle}>Company</div>
          <div className={styles.recordDetail}>{profile?.company || '-'}</div>

          <div className={styles.recordTitle}>Primary Address</div>
          <div className={styles.recordDetail}>
            {[address?.address1, address?.address2, address?.address3]
              .filter(Boolean)
              .join(', ') || '-'}
          </div>

          <div className={styles.recordTitle}>Primary City</div>
          <div className={styles.recordDetail}>{address?.city}</div>

          <div className={styles.recordTitle}>Primary State</div>
          <div className={styles.recordDetail}>{address?.state}</div>

          <div className={styles.recordTitle}>Primary Country</div>
          <div className={styles.recordDetail}>{address?.country}</div>

          <div className={styles.recordTitle}>Primary Phone</div>
          <div className={styles.recordDetail}>{phoneNumber?.phone || '-'}</div>

          <div className={styles.recordTitle}>Registration Blocked</div>
          <div className={styles.recordDetail}>
            {profile?.isBlocked ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      <footer>
        {profile?.id && (
          <>
            {isIneligibleAsWinner && (
              <p className={styles.error}>
                This record cannot be selected as winner since the other record
                contains a CertMaster CE product.
              </p>
            )}
            <Button
              className={styles.button}
              disabled={isIneligibleAsWinner}
              onClick={() => onSelect(profile?.id)}
              variant={
                isSelected ? ButtonVariants.Primary : ButtonVariants.Secondary
              }
            >
              Select as Winner
            </Button>
          </>
        )}
      </footer>
    </div>
  );
};
